<template>
    <div>
        <div class="row my-3">
            <h1 class="col-sm-12 text-right">Créditos</h1>
        </div>

        <div class="row">
            <div class="col-sm-3">
                <form @submit.prevent="obtener_solicitudes">
                    <div class="input-group mb-3">
                        <input v-model="buscar" type="text" class="form-control" :disabled=" solicitud_tipo=='7' ? true : false" placeholder="Solicitud ID">
                        <div class="input-group-append">
                            <button class="btn btn-secondary" :disabled=" solicitud_tipo=='7' ? true : false">Buscar</button>
                        </div>
                    </div>
                </form>
                     
            </div>
            <div class="input-group mb-3 col-sm-3">
            <div class="input-group-prepend">
                <button class="btn-group btn btn-danger" @click="eliminar_filtros"> <b>X</b></button>
            </div>
                <form @submit.prevent="obtener_solicitudes">
                    <select v-model="solicitud_tipo" name="tipo_solicitud" id="tipo_solicitud" class="form-control">
                        <option value="null" disabled>Filtrar Solicitudes</option>     
                        <option value="0">Cancelada</option>     
                        <option value="1">Cliente</option>     
                        <option value="2">Callback</option>     
                        <option value="3">Opinion de Valor</option>     
                        <option value="4">Preautorizacion</option>     
                        <option value="5">Documentacion</option>     
                        <option value="6">Autorizacion</option>     
                        <option value="7">Ejercido</option>     
                        <option value="8">Desarrollo Empresarial</option>     
                    </select>
                    <button v-show="false" id="submit_filtro">Filtrar</button>
                </form>  
            </div>            
            <div class="col-sm-6">
                <Paginacion :paginacion="sol_paginacion" @update="obtener_solicitudes" />
            </div>
        </div>

        <div class="row">
            <div v-for="solicitud in solicitudes" :key="solicitud.id" class="col-sm-3">
                <div class="card mb-3">
                    <div class="card-header">
                        Solicitud <strong>{{ solicitud.id}}</strong>
                    </div>
                    <div class="card-body">
                        <ul class="list-info">
                            <li class="text-truncate"><i class="fa-solid fa-user"></i> <span :title="solicitud.nombre">{{ solicitud.nombre }}</span></li>
                            <li class="text-truncate"><i class="fa-solid fa-envelope"></i> <span :title="solicitud.email">{{ solicitud.email }}</span></li>
                            <li><i class="fa-solid fa-calendar-days"></i> Alta: <span title="Fecha de creación"></span>{{ solicitud.created_at }}</span></li>
                            <li><i class="fa-solid fa-calendar-days"></i> Últ modificación: <span title="Fecha de última modificación"></span>{{ solicitud.updated_at }}</span></li>
                            <li><i class="fa-solid fa-question"></i> Medio: <span title="Medio de información"></span>{{ solicitud.medio_informacion.nombre }}</span></li>
                            <li><i class="fa-solid fa-money-bill-1-wave"></i> Monto solicitado: <span title="Monto solicitado"></span>${{ $helper.moneyFormat(solicitud.importe_solicitado) }}</span></li>
                            <li><i class="fa-solid fa-check"></i> Monto pre-autorizado: <span title="Monto pre-autorizado"></span>${{ $helper.moneyFormat(solicitud.importe_pre_solicitado) }}</span></li>
                            <li><i class="fa-solid fa-image"></i> Modelo imágenes: <span title="Modelo imágenes"></span></li>
                        </ul>
                        <div class="text-right">
                            <button class="btn btn-info" @click="ver_solicitud(solicitud)">Revisar solicitud</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Paginacion :paginacion="sol_paginacion" @update="obtener_solicitudes"/>

        <Solicitud v-if="modal_solicitud" :sol="solicitud" @close="cerrar_modal" />
    </div>
</template>

<script>
import apiSolicitudes from '@/apps/solicitudes/api/solicitudes'
import Paginacion from './Dashboard/Paginacion'
import Solicitud from './Solicitud'

export default {
    components: {
        Paginacion, Solicitud
    }
    ,data() {
        return {
            solicitudes: [],
            sol_paginacion: {
                current_page: 1,
                from:1,
                last_page:1,
                per_page: 20,
                total: 200
            }
            ,modal_solicitud: false
            ,solicitud: {
                id: 0
                ,scoring: {
                    buro: []
                    ,avatar: null
                    ,ocr: []
                }
                ,leadscore: []
            }
            ,buscar: null
            ,solicitud_tipo:null
            // ,options:{
            //     page:1,
            //     order_col: 'id',
            //     order_dir:'desc',
            //     limit:20,

            //     filters:[
            //         {
            //             columna:'status',
            //             operador:'equal_to',
            //             valor1:2,
            //         }
            //     ]

            // }
        }
    }
    ,mounted() {
        this.obtener_solicitudes();
    }
    ,methods: {
        async obtener_solicitudes(paginacion) {
            try {
                let res = null;

                if(typeof paginacion == 'undefined')
                    res = (await apiSolicitudes.obtener_solicitudes(this.sol_paginacion.current_page, null)).data;
                
                else if(paginacion && this.solicitud_tipo!==null)
                    res = (await apiSolicitudes.obtener_solicitudes(paginacion.current_page, null, this.solicitud_tipo)).data

                else
                    res = (await apiSolicitudes.obtener_solicitudes(paginacion.current_page, this.buscar)).data

                this.solicitudes = res.data;
                delete res.data;
                this.sol_paginacion = res;
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        }
        ,ver_solicitud(sol) {
            this.solicitud = sol;
            this.modal_solicitud = true;
        }
        ,cerrar_modal() {
            this.solicitud = {
                id: 0
            }
            this.modal_solicitud = false;
        },
        submit_solicitud_tipo_form(){
            document.getElementById('submit_filtro').click();
        },
        eliminar_filtros(){
            this.buscar = null;
            this.solicitud_tipo = null;
            this.obtener_solicitudes();
        }
    }
    ,computed: {
        alianza() {
            return this.$auth.can('creditos','alianza_soc') ? 'SOC' : 'FRD';
        },
        solicitudes_filtradas() {

            let solicitudes = [];
            this.$log.info('buscar: ', this.buscar);

            if (this.buscar === null)
                return this.solicitudes;

            for(let i=0; i<this.solicitudes.length; i++) {
                let id = this.solicitudes[i].id.toString();
                let indexOf = id.indexOf(this.buscar);
                if (indexOf > -1)
                    solicitudes.push(this.solicitudes[i]);
            }

            return solicitudes;
        }
    },
    watch:{
        solicitud_tipo(){
            if(this.solicitud_tipo!==null)
                this.submit_solicitud_tipo_form();
            

        }
    }
}
</script>

<style lang="scss">
    .list-info {
        list-style: none;
        margin: 0px;
        padding: 0px;

        li {
            margin: 5px 0px;
            padding: 0px;


            i {
                margin-right: 10px;
            }
        }
    }
</style>